<template>
  <div class="sub-mt-2 sub-mr-2">
    <button class="btn btn-secondary" @click="$router.push('/sget')">Return to ShivaGet</button>
    <h1>Test Electron Exec</h1>
    <button class="btn btn-primary" @click="printEnv">Print Env</button>
    <button class="btn btn-primary" @click="runShivaGetTest">Run ShivaGetTest.bat</button>
    <br />
    <div class="form-inline sub-mr-2">
      <b-input type="text" v-model="folderPath" />
      <button class="btn btn-primary" @click="checkFolderExists">Check Folder Exists</button>
    </div>
    <div class="form-inline sub-mr-2">
      <b-input type="text" v-model="filePath" />
      <button class="btn btn-primary" @click="checkFileChecksum">Check File Checksum</button>
    </div>
    <div>Output</div>
    <pre>{{ output }}</pre>
  </div>
</template>

<script>
import { getEnv, runCommand, checkFolderExists, checkFileChecksum } from '@/views/sget/shivarun';

export default {
  data() {
    return {
      folderPath: "D:\\HelloWorld",
      filePath: "ShivaGetEngine.bat",
      output: '',
      env: {}
    }
  },
  async mounted() {
    this.env = await getEnv();
  },
  methods: {
    async printEnv() {
      this.output += "Env: " + JSON.stringify(await getEnv()) + "\n";
    },
    runShivaGetTest() {
      this.output = "Running command\n";
      runCommand("ShivaGetTest.bat",
        ["Hello", `C:\\UE Test\\`, "2"],
        { env: { TOKEN: `{"json":"token"}` },
          windowsVerbatimArguments: true,
        },
        (output) => {
          this.output += output;
        }, () => {
          this.output += "Complete\n";
        });
    },
    async checkFolderExists() {
      let res = await checkFolderExists(this.folderPath);
      this.output += `Folder "${this.folderPath}" exists: ${res}\n`;
    },
    async checkFileChecksum() {
      let res = await checkFileChecksum(this.env.shivaGetPath + "\\" + this.filePath);
      this.output += `File "${this.filePath}" checksum: ${res}\n`;
    }
  }
}
</script>